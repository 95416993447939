import React, { FC, useState } from 'react';

import Api from '~/Api';
import Loader from '~/components/atoms/Loader';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import ConfirmationModal from '~/components/modals/ConfirmationModal';
import { useModalContext } from '~/contexts/modal/ModalContext';
import EmailUpdatingFailedModal from '~/modules/users/components/UserItem/ActionsBlock/Email/EmailUpdatingFailedModal';
import { ExtendedUserType } from '~/modules/users/types';

import Email from './Email';
import ExtraWords from './ExtraWords';
import Password from './Password';
import styles from './styles.module.scss';

type Props = {
  user: ExtendedUserType;
};

const ActionsBlock: FC<Props> = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(user.email);
  const [oneTimePassword, setOneTimePassword] = useState('');
  const [currentExtraWords, setCurrentExtraWords] = useState(
    user.detectors_extra_words_left,
  );

  const { showSnackbar } = useSnackBarContext();
  const { handleOpenModal } = useModalContext();

  const withConfirmation = (
    title: string,
    text: string,
    onSubmit: () => Promise<void>,
  ) => {
    return async (): Promise<void> => {
      handleOpenModal({
        component: ({ onClose }) => (
          <ConfirmationModal
            title={title}
            text={text}
            onSubmit={async (): Promise<void> => {
              onClose();
              await onSubmit();
            }}
            onRefuse={onClose}
          />
        ),
      });
    };
  };

  const handleGenerateOnetimePassword = async (): Promise<void> => {
    try {
      setIsLoading(true);
      setOneTimePassword('');
      const newPassword = await Api.updateUserPassword(user.id);
      setOneTimePassword(newPassword);
      showSnackbar('New password set', 'success');
    } catch (e) {
      showSnackbar('Updating failed');
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateExtraWords = async (amount: number): Promise<void> => {
    try {
      setIsLoading(true);

      const newExtraWords = await Api.updateUserExtraWords(user.id, amount);
      setCurrentExtraWords(newExtraWords);
      showSnackbar('Extra words amount updated', 'success');
    } catch (e) {
      showSnackbar('Updating failed');
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateEmail = async (formData: {
    email: string;
  }): Promise<void> => {
    try {
      setIsLoading(true);

      const newEmail = await Api.updateUserEmail(user.id, formData.email);
      setCurrentEmail(newEmail);
      showSnackbar('User email updated', 'success');
    } catch (e: any) {
      if (e.message === 'email already set') {
        showSnackbar('Email already set');
        return;
      }
      if (e.message === "can't set existing email") {
        showSnackbar('This email is already in use by another user.');
        return;
      }
      handleOpenModal({
        component: () => <EmailUpdatingFailedModal />,
      });
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Loader isLoading={isLoading} />

      <div className={styles.email}>
        <Email
          isDisabled={isLoading}
          currentEmail={currentEmail}
          onSubmit={async (formData: { email: string }): Promise<void> => {
            await withConfirmation(
              'User email change',
              "This action will change the user's email to a new one",
              () => handleUpdateEmail(formData),
            )();
          }}
        />
      </div>

      <div className={styles.password}>
        <Password
          isDisabled={isLoading}
          oneTimePassword={oneTimePassword}
          onSubmit={withConfirmation(
            'User password change',
            "This action will change the user's password to a new one. You'll see a new generated password you can share with a user",
            handleGenerateOnetimePassword,
          )}
        />
      </div>

      <div className={styles.extra}>
        <ExtraWords
          isDisabled={isLoading}
          currentExtraWords={currentExtraWords}
          onExtraWordsUpdate={async (amount: number): Promise<void> =>
            withConfirmation(
              'New extra words amount',
              `Confirm you'd like to set a new word amount: ${amount.toLocaleString()}`,
              () => handleUpdateExtraWords(amount),
            )()
          }
        />
      </div>
    </div>
  );
};

export default ActionsBlock;
